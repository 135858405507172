import React from 'react';
import PortfolioLayout from '../components/PortfolioLayout.js';
import { videos } from '../data/galleryData.js';
import "../styles/Gallery.css";
import youtubeLogo from "../img/yt_logo_rgb_light.png";

export default () => {
  const [currentVideo, setCurrentVideo] = React.useState(null);

  return (  
    <PortfolioLayout>
      <div className="content gallery">
        <div className="aux-info">
          <div className="with-margin aux-info-item portfolio">
          <h2>Storyboards and Animation</h2>
            <div className="video-container">
              {currentVideo ? (<div>
                <h3>{currentVideo.name}</h3>
                <video controls key={currentVideo.filename}>
                <source src={`${process.env.GATSBY_AWS_S3_BASE_URL}/videos/${currentVideo.filename}.${currentVideo.extension || 'mp4'}`} type="video/mp4"/>
                Your browser does not support the video tag.
                </video>
                <p className="description">{currentVideo.description}</p>
              </div>) : ""}
              
              <div className="video-thumbs">
                {videos.map(file => (<div className="video-thumb-container">
                <div className="play-overlay">
                  <p>{file.name}</p>
                </div>
                <button onClick={() => setCurrentVideo(file)}><img src={`${process.env.GATSBY_AWS_S3_BASE_URL}/videos/thumbs/${file.filename}-thumb.jpg`} alt={file.description}/></button>
                </div>))}
                <p className="youtube-link">See more: <a href="https://www.youtube.com/channel/UCYV0ZZuG-QLWlgtVOQEhcUA"><img src={youtubeLogo}/></a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PortfolioLayout>
  );
}
