export const illustrations = [{
  filename: "SwanPrincess",
  name: "Swan Princess Digital Painting",
  description: "Digital painting inspired by the tale of the Swan Princess, 2015"
}, {
  filename: "Morgon_Deth",
  name: "Riddle of Stars Character Designs",
  description: "Initial character designs based on the Riddlemaster trilogy (Riddle of Stars) by Patricia McKillip, 2020"
}, {
  filename: "Ch00_023",
  name: "The Starless Land Volume 1 Cover",
  description: `Volume 1 cover of my online graphic novel, "The Starless Land", 2019`
}, {
  filename: "SketchSpread-Ril",
  name: "Character Sketches and Designs - Ril",
  description: `Concept development and age progression for a major character from "The Starlees Land", 2020`
}, {
  filename: "SketchSpread-Kagely",
  name: "Character Sketches and Designs - Kagely",
  description: `Concept development and age progression for an important character from "The Starless Land", 2020`
}, {
  filename: "Ch01_035",
  name: "Myth Character Designs",
  description: `TSL comic page showing a more exaggerated design style used to portray a storyteller's redition of a tale, 2020`
}, {
  filename: "ShimurliAction",
  name: "Shimurli Promotional Art",
  description: `Promotional art featuring future character designs to tease the direction of "The Starless Land", 2020`
}, {
  filename: "CliffCity",
  name: "Cliff City Digital Painting",
  description: "An environment for a digital painting class, 2014"
}, {
  filename: "GarranCover",
  name: "Gods of Garran Cover",
  description: `Cover for the Amazon novel, "The Gods of Garran" by Meredith Skye. Originally published in 2013, slightly retouched for a new version in 2021.`
}
];

export const figureDrawings = [{
  filename: "3Hour",
  name: "Conte and Chalk - 3 Hours",
  description: "3 hour study, conte and chalk on tinted paper, 2013"
}, {
  filename: "5Minutes",
  name: "Conte - 5 Minutes page",
  description: "Multiple 5 minute gestures, conte on newsprint, 2013"
}, {
  filename: "40Minutes",
  name: "Conte - 40 Minutes",
  description: "40 minute study, conte on newsprint, 2013"
}, {
  filename: "DigitalGestures",
  name: "Digital Gestures",
  description: "Digital collection focusing on body construction and lines of action, 2020. The ice dancer was drawn from a photograph, all others from life."
}, {
  filename: "PaintedPortrait",
  name: "Portrait - Watercolor and Ink",
  description: "Portrait from a live model, watercolor and ink on newsprint, 2012"
}, {
  filename: "SketchbookExaggerations",
  name: "Sketchbook Stylizations",
  description: "Exaggerations and stylizations from my sketchbook"
}, {
  filename: "1Minute",
  name: "Conte - 1 Minute",
  description: "1 minute gesture, 2013"
}, {
  filename: "DeerDrawing",
  name: "Graphite Deer Study",
  description: "Graphite study of a stuffed deer in a museum, 2012"
}, {
  filename: "CharcoalGestures",
  name: "Charcoal Studies - ~20 minutes",
  description: "Charcoal sketches from a life drawing session, 2014"
}, {
  filename: "ClothedGesture",
  name: "Conte - 10 Minutes",
  description: "10 minute gesture, 2012"
}, {
  filename: "2Hour",
  name: "Conte - 2 Hours",
  description: "2 hour study, conte on bristol paper, 2013"
}];

export const videos = [{
  filename: "Anuin",
  extension: 'mov',
  name: "Riddle of Stars Animatic, 2021",
  description: `Morgon walked into Anuin thinking that killing Deth would be easy - he would rid the realm of that remorseless betrayer and try to pick up the pieces of his life. But in the attempt, he comes up against something strangely intractable. Afterwards, he is haunted by the dispassionate stillness with which Deth faced him when Morgon raised his sword to kill him. Morgon feels that what he encountered in Deth was a kind of power, but he is unable to define what exactly it was.

If my handling of the scene is working properly, viewers should be confused about what just happened here. It doesn't add up. The first question I hope viewers will ask is: What is Morgon trying to get from Deth that he can't get? It's complicated, but I hope this much is clear: The thing Morgon can't get is the truth!

The other question is: What is Deth hiding from Morgon? I included hints for three different answers, but I think only one is theoretically guessable. The answers are major spoilers for the book, so I will reserve that for the end of my comments.

The main reason Deth manages to pull this off is because Morgon doesn't really want to kill him. Morgon is in denial that he could have been so misled about Deth's character, so he grills him, trying to flush out the possibility that Deth is just playing villain. (That is far-fetched, but Morgon is right to be suspicious that Deth is taking a middle road, pretending to be indifferent towards Morgon. Deth has motive to be ambiguous.) Morgon's desperation grows the longer this goes on without yielding a conclusive answer. He finally decides to use an ultimatum to force an answer, but that (at least according to my interpretation) was a blunder; Deth was seconds away from yielding when Morgon shouted that question at him. The ultimatum clues Deth to Morgon's state of mind, encouraging him to gamble instead of yielding. Morgon had enough leverage here to force a yield; he simply misplayed and lost to Deth's emotional intelligence.

I hope the scene is entertaining without an understanding of Deth's side because Deth is willing to go to such bizarre lengths to avoid answering what seem like simple questions, and because once he realizes he's in control of the situation, he trashes Morgon's threat in a way that makes him look unbeatable. But it's far more entertaining with an understanding of Deth's side. Deth is just terrible. So, what is Deth hiding from Morgon? (Again, major spoiler warning!)
  
   1. Deth cares about Morgon. It was a clever move by Morgon to say that he was going to kill Deth for his harping at Erlenstar because that was kindly motivated. Deth has to admit that he's playing villain or accept being killed unfairly. Morgon would not have elicited the same reaction if he'd said he was going to kill Deth for his betrayal, which I'm sure is why he did it. But Deth doesn't rise to the provocation.
   2. The seriousness of the leverage Morgon is using on him. Deth is ridiculously, impossibly stoic. He knows that his death would have catastrophic consequences, yet he manages to play this like Morgon didn't have enough leverage to make him answer a simple question.
   3. Deth is a wizard; he is entirely capable of defending himself. It amuses me to no end that if Morgon had forced a yield, it likely would have taken the form of Deth attacking Morgon with powers he wasn't supposed to have. Deth was bound to play this trump to save his life unless he simply gave up in despair, which was possible in this situation. He is quite convinced that he has to keep it secret in order to have a path to victory.
  
In other words, Morgon tried to force a powerful but vulnerable ally to abandon his cover story and failed, fortunately - despite having unwittingly used the fate of the entire realm as leverage.`
}, {
  filename: "CallingCard",
  name: "Calling Card, 2016",
  description: `My final student film, using watercolor mixed with glycerin on glass. This was a style exploration using my comic character Ril at a younger age than in my comic (related to my animatic project). This was very ambitious, seeing as I only had a month to do all the animation, and there were no do-overs since most fixes would have meant reanimating an entire shot. I'm keenly aware of some timing issues and drawing problems, but there was also a lot of good stuff that worked. I doubt I'll get around to doing more fixes, so it looks like I have to put it out there the way it is. There is a slight spoiler risk for readers of my comic.`
}, {
  filename: "TSL_Ep2",
  name: "TSL Animatic Test, 2020",
  description: `These are storyboards for an idea based off of my comic characters. I don't have any real plan to get them produced, but the ideas keep coming, so I decided to capture them as storyboards for practice. If they're looking good enough, future production might be a possibility, but I'm a ways out from being able to serioiusly consider that.
  
I envision these as TV episodes, but these would be relatively high budget due to the camera work and posing. I intended the project to be stylistically different from most animated shows currently produced in the US, as my style draws a lot from anime. I've known for a long time that the kind of shows that I'm most interested in working on generally aren't being made in the West, but there seems to be a great deal of interest and market for offerings that focus on artistic design above comedic entertainment value and that explore deeper themes interesting to older audiences.

The farther I get into this project, the greater the risk of spoilers for readers of my comic. For now, the animatic isn't enough to give away anything. In this episode my character Ril is attempting to hang out with his cousins - but he doesn't want them to find out he's a relative or tell them anything personally identifiable.
`
}, {
  filename: "Traffic",
  name: "Traffic, 2015",
  description: `Short film comparing kinetic ball machines to street traffic, assembled in Toon Boom Animate.`
}, {
  filename: "Claymation",
  name: "Snacking in a Creepy Park, 2015",
  description: "Simple claymation piece that shows animation of physical media."
}, {
  filename: "RearLighting",
  name: "Watercolor and Sand Abstract, 2015",
  description: "Abstract experimental piece using watercolor paint (mixed with glycerin) and sand."
}];

export const extras = [{
  filename: "MerchantSailorRunawayNoble",
  description: "A quick concept for Shimurli's parents"
}];